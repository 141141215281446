.leaderboard tbody tr:nth-of-type(1) th,
.leaderboard tbody tr:nth-of-type(1) td {
    @apply bg-green-700;
}

.leaderboard tbody tr:nth-of-type(2) th,
.leaderboard tbody tr:nth-of-type(2) td {
    @apply bg-green-800;
}

.leaderboard tbody tr:nth-of-type(3) th,
.leaderboard tbody tr:nth-of-type(3) td {
    @apply bg-green-900;
    @apply border-b-2;
    @apply border-green-700;
    @apply shadow-sm;
}