@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-p5 > canvas:nth-of-type(2) {
  display: none;
}

.react-p5 {
  display: flex;
  justify-content: stretch;
  align-content: center;
}

.react-p5 canvas {
  height: auto !important;
  width: 100% !important;
}

.card .card {
  @apply bg-base-200;
}

.card-title {
  @apply text-primary;
}

.card p {
  @apply text-neutral;
}

.menu {
  @apply bg-base-200;
}

.input-group-vertical span {
  @apply bg-base-200;
}

.dashboard .stat-value {
  @apply text-xl;
  @apply sm:text-3xl;
}

.card {
  background-color: rgba(34, 34, 34, 0.8);
}

.dashboard {
  z-index: 100;
}

.progress:indeterminate::after {
  @apply bg-primary;
}

.mining {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
}

.bg-semitransparent {
  background-color: rgba(34, 34, 34, 0.8);
}